import React from 'react';
import { SxProps, Box } from '@mui/system';
import { isReactApp } from '../../utils/reactUtils';

export interface ImageProps {
  src: string;
  alt: string;
  layout?: 'fill' | 'contain' | 'cover';
  width?: number | string;
  height?: number | string;
  sx?: SxProps; // Separate MUI `sx` prop
  style?: React.CSSProperties;
  loading?: 'eager' | 'lazy';
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  objectPosition?: string;
  loaderEnable?: boolean;
  onLoad?: () => void;
}

function ReactImage({
  src,
  alt,
  layout,
  width,
  height,
  sx, // separate MUI `sx` prop,
  style,
  onError,
  objectPosition,
  priority,
  onLoad,
  ...props
}) {
  const getLayoutStyle = (): React.CSSProperties => {
    switch (layout) {
      case 'fill':
        return { objectFit: 'cover' };
      case 'contain':
        return { objectFit: 'contain' };
      default:
        return { objectFit: 'cover' };
    }
  };

  const getDimension = (amount: string | number) => {
    if (isReactApp && (amount === '0' || amount === 0)) {
      return null;
    }

    return amount;
  };

  const imageStyle: React.CSSProperties = {
    ...getLayoutStyle(),
    objectPosition: objectPosition ?? 'center center',
    width: getDimension(width) ?? '100%',
    height: getDimension(height) ?? 'auto',
    ...style
  };

  return (
    <Box sx={sx}>
      <img
        src={src?.src ? src?.src : src} // You can add more sources here if needed
        alt={alt}
        style={imageStyle}
        loading={priority ? 'eager' : 'lazy'}
        onError={onError}
        onLoad={onLoad}
        {...props}
      />
    </Box>
  );
}

export default ReactImage;
