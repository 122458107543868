// lib
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Grid, useTheme } from '@mui/material';
// src
import HorizontalCarouselWrapper from '@foxtons/fdl/layouts/HorizontalCarouselWrapper/index';
import { PropertyListCard } from '@foxtons/fdl/components/PropertyListCard/index';
// styles&utils
import {
  getUserFavoritePropertiesList,
  HttpResponseDataType,
  togglePropertyFavorite
} from '@utils/httpRequestConfigs/httpRequests';
import {
  getAuthActionFromSessionStorage,
  getPrice,
  photoAssetUrl,
  saveAuthActionInSession
} from '@utils/helperFunctions';
import propertyActionConstant from '@foxtons/fdl/utils/Constants/propertyActionConstant';
import { PropertyListNewHomesCard } from '@foxtons/fdl/components/PropertyListNewHomesCard/index';

import styles from './styles';

export interface RecommendedPropertiesProps {
  title?: string;
  recommendedPropertiesData: HttpResponseDataType;
  savedProperties?: HttpResponseDataType;
  userToken?: string;
  baseUrlPropertyType?: string;
  isRecommendedProperty: boolean;
  settingSavedProp?: (payload: HttpResponseDataType) => void;
  isSearch?: boolean;
  handleSearchNow?: (e) => void;
  isLoading?: boolean;
  isNewDevelopment?: boolean;
  isAnimated?: boolean;
  tabs?: string[];
  activeTab?: string;
  handleTabChange?: (val: string) => void;
  marketPageView?: boolean;
  schoolName?: string;
  schoolWebName?: string;
  isSchoolDetailPage?: boolean;
  isLivingIn?: boolean;
  pricePeriod?: 'PerWeek' | 'PerMonth';
}

interface StoredPropertiesIds {
  properties: number[];
}

export interface PropertiesProps {
  property: {
    property: {
      propertyReference: string;
    };
    instructionPrice: {
      instructionPriceType: string;
    };
  };
}

export interface PropertiesImagesProps {
  index: string;
  propertyReference: string;
  filename: string;
  timestamp: string;
}

function RecommendedProperties({
  title = 'fold title',
  recommendedPropertiesData,
  savedProperties,
  userToken = '',
  baseUrlPropertyType,
  isRecommendedProperty = false,
  isSearch = false,
  settingSavedProp,
  handleSearchNow,
  isLoading = false,
  isNewDevelopment = false,
  isAnimated,
  tabs = [],
  activeTab = '',
  handleTabChange,
  marketPageView = false,
  schoolName = '',
  schoolWebName = '',
  isSchoolDetailPage = false,
  isLivingIn = false,
  pricePeriod
}: RecommendedPropertiesProps) {
  const router = useRouter();
  const { spacing } = useTheme();

  const [
    orderedRecommendedPropertiesData,
    setOrderedRecommendedPropertiesData
  ] = useState([]);
  const [itemPerSlide, setItemPerSlide] = useState(1);

  // to order the list based on the recent search first
  useEffect(() => {
    const orderedArray = [];
    const storedData = localStorage.getItem('search_history');
    let arr: StoredPropertiesIds = { properties: [] };

    if (storedData) {
      arr = JSON.parse(storedData);

      arr.properties.forEach((id) => {
        const matchingItem = recommendedPropertiesData?.find(
          (item) => item.propertyId === id
        );

        if (matchingItem) {
          orderedArray.push(matchingItem);
        }
      });

      const remainingItems =
        recommendedPropertiesData?.filter(
          (item) => !arr.properties.includes(item.propertyId)
        ) || [];

      setOrderedRecommendedPropertiesData([...orderedArray, ...remainingItems]);
    } else {
      setOrderedRecommendedPropertiesData(recommendedPropertiesData);
    }
  }, [recommendedPropertiesData]);

  // Login-on on click of login button for sneak-peak properties
  const handleLoginClickSneakPeek = (
    event: React.MouseEvent,
    card: HttpResponseDataType
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (!userToken) {
      if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'new') {
        saveAuthActionInSession({
          actionName: '',
          hasLoggedIn: false,
          redirectUrl: router.asPath,
          propertyData: {
            area: `${card?.propertyBlob?.floorArea} sq.ft`,
            bath: card?.bathrooms || '',
            bed: card?.bedrooms || '',
            locationName: card?.propertyBlob.locationName || '',
            postcodeShort: card?.postcodeShort || '',
            streetName: card?.streetName || '',
            isSneakPeek: card?.isSneakPeek || false,
            price: getPrice(
              card?.instructionType || '',
              card?.priceTo || 0,
              card?.priceFrom || 0
            ),
            image: photoAssetUrl(
              card?.propertyBlob?.assetInfo?.assets?.photos[0]
                ?.propertyReference || '',
              card?.propertyBlob?.assetInfo?.assets?.photos[0]?.filename || '',
              card?.propertyBlob?.assetInfo?.assets?.photos[0]?.timestamp || ''
            )
          }
        });

        router.push('/auth/action');
      } else {
        const redirectUrl = {
          redirect_url: `${process.env.NEXT_PUBLIC_URL}${router.asPath}`
        };

        localStorage.setItem('sneakpeek', JSON.stringify(redirectUrl));

        router.push('/account/welcome/sneakpeek/');
      }
    }

    return;
  };

  // API to get saved properties
  const getUserSavedProperties = async () => {
    const { response } = await getUserFavoritePropertiesList();

    settingSavedProp(response?.data);
  };

  const isSavedProperty = (
    propertyReference: string,
    propertyInstructionType: string
  ) => {
    const property = savedProperties?.find(
      (item: PropertiesProps) =>
        item?.property?.property?.propertyReference === propertyReference &&
        item?.property?.instructionPrice?.instructionPriceType ===
          propertyInstructionType
    );

    return Boolean(property);
  };

  const toggleSaveProperty = async (
    propertyReference: string,
    instructionType: string
  ) => {
    const isSaved = isSavedProperty(propertyReference, instructionType);

    if (isSaved) {
      const newSavedArray = savedProperties.filter(
        (item) =>
          !(
            item.property?.property?.propertyReference === propertyReference &&
            item.property?.instructionPrice?.instructionPriceType ===
              instructionType
          )
      );

      settingSavedProp(newSavedArray);
    } else {
      const selectedProperty = savedProperties?.find(
        (item) => item.propertyReference === propertyReference
      );

      if (selectedProperty) {
        const {
          propertyId,
          propertyReference,
          instructionType,
          instructionTypeShort
        } = selectedProperty;

        const newSavedData = [
          ...savedProperties,
          {
            searchPropertyType: 'SAVED_PROPERTY',
            property: {
              instructionPrice: {
                instructionPriceType: instructionType,
                instructionPriceTypeShort: instructionTypeShort
              },
              property: {
                propertyId: propertyId,
                propertyReference: propertyReference
              }
            }
          }
        ];

        settingSavedProp(newSavedData);
      }
    }

    const response = await togglePropertyFavorite(
      instructionType,
      propertyReference,
      isSaved
    );

    if (response.success) {
      getUserSavedProperties();
    }
  };

  const saveIconClick = (
    event: React.MouseEvent,
    card: HttpResponseDataType
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (userToken) {
      toggleSaveProperty(card?.propertyReference || '', card?.instructionType);
    } else {
      if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'new') {
        saveAuthActionInSession({
          actionName: propertyActionConstant.saveProperty,
          hasLoggedIn: false,
          redirectUrl: router.asPath,
          formData: {
            propertyReference: card?.propertyReference || '',
            instructionType: card?.instructionType || ''
          },
          propertyData: {
            area: `${card?.propertyBlob?.floorArea} sq.ft`,
            bath: card?.bathrooms || '',
            bed: card?.bedrooms || '',
            locationName: card?.propertyBlob.locationName || '',
            postcodeShort: card?.postcodeShort || '',
            streetName: card?.streetName || '',
            isSneakPeek: card?.isSneakPeek || false,
            price: getPrice(
              card?.instructionType || '',
              card?.priceTo || 0,
              card?.priceFrom || 0
            ),
            image: photoAssetUrl(
              card?.propertyBlob?.assetInfo?.assets?.photos[0]
                ?.propertyReference || '',
              card?.propertyBlob?.assetInfo?.assets?.photos[0]?.filename || '',
              card?.propertyBlob?.assetInfo?.assets?.photos[0]?.timestamp || ''
            )
          }
        });
        router.push('/auth/action');
      } else {
        const propertyData = {
          type: 'save_property',
          data: {
            property_id: card?.propertyId || '',
            rating: 5,
            return_url: `${process.env.NEXT_PUBLIC_URL}${router.asPath}`
          }
        };

        localStorage.setItem('account_action', JSON.stringify(propertyData));

        router.push(
          `/account/welcome/save_property?property_id=${card?.propertyId || ''}`
        );
      }
    }
  };

  const shouldButtonsShow = () => {
    if (
      (marketPageView && orderedRecommendedPropertiesData.length < 4) ||
      (isSchoolDetailPage && !orderedRecommendedPropertiesData.length)
    ) {
      return false;
    }

    return true;
  };

  // useEffect for fetching saved properties of the user
  useEffect(() => {
    if (userToken) {
      getUserSavedProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // useEffect for saving properties after returning from login page
  useEffect(() => {
    const { action, redirectUrl, formData, hasLoggedIn } =
      getAuthActionFromSessionStorage();

    if (userToken && redirectUrl === router.asPath && hasLoggedIn) {
      if (action === propertyActionConstant.saveProperty) {
        const isAlreadySaved = isSavedProperty(
          formData?.propertyReference,
          formData?.instructionType
        );

        if (!isAlreadySaved) {
          toggleSaveProperty(
            formData?.propertyReference,
            formData?.instructionType
          );
        }

        sessionStorage.removeItem('propertyAction');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedProperties]);

  useEffect(() => {
    function handleResize() {
      let itemWidth = 0;
      let windowWidth = 0;

      if (window.innerWidth < 600) {
        windowWidth = window.innerWidth - 32 - 38; // 2rem padding (1+1 on left and right) + spacing(2) between cards
        itemWidth = 336; // 21rem card width
      } else if (window.innerWidth < 900) {
        windowWidth = window.innerWidth - 32 - 38; // 2rem padding (1+1 on left and right) + spacing(2) between cards
        itemWidth = 308; // 19.25rem card with
      } else {
        windowWidth = window.innerWidth - 160 - 57; // 10rem padding (80+80 on left and right) + spacing(3) spacing between cards
        itemWidth = 308; // 19.25rem card with
      }

      const numberOfItemsPerSlide = Number(
        (windowWidth / itemWidth).toFixed(2)
      );

      setItemPerSlide(numberOfItemsPerSlide < 1 ? 1 : numberOfItemsPerSlide);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={
        isLivingIn
          ? styles.LivingContainer
          : isSchoolDetailPage && orderedRecommendedPropertiesData?.length > 0
          ? styles.schoolContainerCards
          : isSchoolDetailPage && orderedRecommendedPropertiesData?.length <= 0
          ? styles.schoolcontainer
          : styles.container
      }
    >
      {isNewDevelopment ? (
        <HorizontalCarouselWrapper
          slide={orderedRecommendedPropertiesData}
          richTitle={title}
          isArrowControlsReq
          isLoading={isLoading}
          isNewDevelopmentCards={true}
          itemsPerSlide={{ 0: { items: itemPerSlide } }}
        >
          {orderedRecommendedPropertiesData?.map((property: any) => {
            return (
              <Grid item paddingRight={spacing(1)} sx={styles.propertyListBox}>
                <PropertyListNewHomesCard property={property} />
              </Grid>
            );
          })}
        </HorizontalCarouselWrapper>
      ) : (
        <HorizontalCarouselWrapper
          slide={orderedRecommendedPropertiesData}
          richTitle={title}
          isArrowControlsReq={shouldButtonsShow()}
          isSearch={isSearch}
          isLoading={isLoading}
          handleSearchNow={(e) => handleSearchNow(e)}
          tabs={tabs}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          schoolName={schoolName}
          schoolWebName={schoolWebName}
          isSchoolDetailPage={isSchoolDetailPage}
          isInfinite={false}
          itemsPerSlide={{ 0: { items: itemPerSlide } }}
        >
          {isSchoolDetailPage && orderedRecommendedPropertiesData?.length < 0
            ? null
            : orderedRecommendedPropertiesData?.map(
                (item: HttpResponseDataType, idx: number) => {
                  return (
                    <Box
                      key={item?.propertyReference}
                      sx={styles.propertyListBox}
                    >
                      <Box position="relative">
                        <PropertyListCard
                          property={item}
                          basePath={baseUrlPropertyType}
                          isRecommendedProperty={
                            item?.isRecommendedProperty
                              ? item?.isRecommendedProperty
                              : isRecommendedProperty
                          }
                          isNew={item?.isNew}
                          isLastViewed={item?.isLastViewed}
                          isTopTenancy={item?.isTopTenancy}
                          isQuickSale={item?.isQuickSale}
                          favorited={isSavedProperty(
                            item?.propertyReference || '',
                            item?.instructionType || ''
                          )}
                          isAnimated={isAnimated}
                          heartIconOnClick={(e) => saveIconClick(e, item)}
                          handleLoginClick={(e) =>
                            handleLoginClickSneakPeek(e, item)
                          }
                          marketPageView={marketPageView}
                          pricePeriod={pricePeriod}
                          lazilyLoadFirstImage={true}
                        />
                      </Box>
                    </Box>
                  );
                }
              )}
        </HorizontalCarouselWrapper>
      )}
    </Box>
  );
}

export const RecommendedPropertiesSection = React.memo(RecommendedProperties);

export default RecommendedProperties;
