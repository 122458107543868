import React, { useRef } from 'react';
import Markdown from 'markdown-to-jsx';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  Box,
  Grid,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dynamic from 'next/dynamic';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import deepmerge from '@mui/utils/deepmerge';
import Link from 'next/link';
import { Button } from '../../components/Button';
import styles from './styles';
import markdownOverrides from '../../utils/MarkdownOverrides';
import useClientOnly from '../../utils/customHooks/useClientOnly';

const AliceCarousel = dynamic(import('react-alice-carousel'), { ssr: false });
const FoxtonsLoader = dynamic(
  () => import('../../components/FoxtonsLoader/index')
);

export interface HorizontalCarouselWrapperProps {
  richTitle: string;
  slide: any;
  children?: any;
  isArrowControlsReq?: boolean;
  isSearch: boolean;
  isLoading: boolean;
  handleSearchNow?: (e) => void;
  isNewDevelopmentCards?: boolean;
  tabs?: string[];
  activeTab?: string;
  handleTabChange?: (val: string) => void;
  isInfinite?: boolean;
  mainContainer?: SxProps;
  slideContainer?: SxProps;
  itemsPerSlide?: {
    [x: string]: {
      items: number;
      itemsFit?: string;
    };
  };
  schoolName?: string;
  schoolWebName?: string;
  isSchoolDetailPage?: boolean;
  paddingRight?: number;
}

export default function HorizontalCarouselWrapper({
  richTitle,
  children,
  isArrowControlsReq = false,
  isSearch = false,
  isLoading = false,
  handleSearchNow,
  isNewDevelopmentCards = false,
  tabs = [],
  activeTab = '',
  handleTabChange,
  isInfinite = true,
  mainContainer,
  itemsPerSlide,
  slideContainer,
  schoolName,
  schoolWebName,
  isSchoolDetailPage = false,
  paddingRight
}: HorizontalCarouselWrapperProps) {
  const theme = useTheme();
  const sliderRef = useRef<AliceCarousel>(null);
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { hasMounted } = useClientOnly();

  const overrides = {
    ...markdownOverrides,
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: styles.headingTitle
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: styles.headingTitle
      }
    },
    p: {
      component: Typography,
      props: {
        variant: 'body1',
        sx: styles.paragraphText
      }
    }
  };

  const responsive = {
    0: { items: 1 },
    380: { items: 1.0 },
    400: { items: 1.03 },
    420: { items: 1.1 },
    600: { items: 1.75 },
    800: { items: 2.1 },
    1100: { items: 3 },
    1400: { items: 4 },
    1800: { items: 5 },
    2100: { items: 6 },
    2500: { items: 7 },
    2900: { items: 8 },
    3300: { items: 9 },
    3700: { items: 10 }
  };

  const resNewDevelopment = {
    0: { items: 1 },
    600: { items: 2 },
    1100: { items: 3 },
    1400: { items: 4 },
    1800: { items: 5 },
    2100: { items: 6 },
    2500: { items: 7 },
    2900: { items: 8 },
    3300: { items: 9 },
    3700: { items: 10 }
  };

  return (
    <Grid container sx={deepmerge(styles.mainContainer, mainContainer)}>
      <Grid item md={isSearch ? 8 : 12}>
        <Markdown options={{ overrides }}>{richTitle}</Markdown>
      </Grid>

      {tabs && tabs.length > 0 && (
        <Grid container gap={1}>
          {tabs.map((tab: string) => (
            <Button
              key={tab}
              variant="secondary"
              size="medium"
              color={activeTab === tab ? 'primary' : 'secondary'}
              sx={
                activeTab === tab
                  ? styles.activeButton
                  : { textTransform: 'none' }
              }
              onClick={() => handleTabChange(tab)}
            >
              <Typography variant="subtitle2" component="span">
                {tab}
              </Typography>
            </Button>
          ))}
        </Grid>
      )}
      {isSearch ? (
        <Grid item md={4} sx={styles.buttonBox}>
          {' '}
          <Button
            variant="primary"
            fullWidth={isMediumScreen}
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={(e) => handleSearchNow(e)}
          >
            Search now
          </Button>
        </Grid>
      ) : null}
      {hasMounted ? null : children.map((item) => item)}
      {!isLoading ? (
        <>
          <Grid
            container
            sx={deepmerge(styles.root, slideContainer)}
            onDragStart={(e) => e.preventDefault()}
          >
            <AliceCarousel
              ref={sliderRef}
              infinite={isInfinite}
              disableButtonsControls={!isArrowControlsReq}
              disableDotsControls
              mouseTracking
              touchTracking
              responsive={
                itemsPerSlide ||
                (isNewDevelopmentCards ? resNewDevelopment : responsive)
              }
              items={children}
              paddingRight={
                typeof paddingRight !== 'undefined' ? paddingRight : 0
              }
              renderNextButton={(e) =>
                e.isDisabled ? null : (
                  <Box sx={styles.rightControl}>
                    <ArrowForwardIosRoundedIcon sx={styles.arrowStyles} />
                  </Box>
                )
              }
              renderPrevButton={(e) =>
                e.isDisabled ? null : (
                  <ArrowBackIosNewRoundedIcon sx={styles.arrowStyles} />
                )
              }
            />
          </Grid>
          {isSchoolDetailPage ? (
            <Box sx={styles.buttonContent}>
              <Box sx={styles.buttonLinkBox}>
                <Link href={`/properties-for-sale/${schoolWebName}?distance=3`}>
                  <Button variant="tertiary" sx={styles.buttonText}>
                    Properties for sale near {schoolName}
                  </Button>
                </Link>
              </Box>
              <Box sx={styles.buttonLinkBox}>
                <Link href={`/properties-to-rent/${schoolWebName}?distance=3`}>
                  <Button variant="tertiary" sx={styles.buttonText}>
                    Properties to rent near {schoolName}
                  </Button>
                </Link>
              </Box>
            </Box>
          ) : null}
        </>
      ) : (
        <Box sx={styles.loader}>
          <FoxtonsLoader open={isLoading} hasBackdrop={false} />
        </Box>
      )}
    </Grid>
  );
}
