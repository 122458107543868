import { SxProps } from '@mui/material';
import { lightTheme, globalSectionPadding } from '../../theme/index';

const { palette, spacing } = lightTheme;

const controlCommonStyles = {
  display: {
    xs: 'none',
    md: 'block'
  },
  internalBox: {
    display: 'flex',
    mx: 'auto',
    maxWidth: '90rem'
  },
  cursor: 'pointer',
  position: 'absolute',
  height: {
    md: '8rem',
    xs: '4rem'
  },
  top: {
    md: 'calc(50% - 3.5rem)',
    xs: 'calc(50% - 1rem)'
  },
  width: 'auto',
  minWidth: '0',
  padding: '0.5rem',
  opacity: '1',
  color: palette.grey['50'],
  fontWeight: 'bold',
  transition: '0.6s ease',
  border: 'none',
  fontSize: '1.5rem'
};

const styles: Record<string, SxProps> = {
  root: {
    position: 'relative',
    minHeight: '24.063rem',
    '.alice-carousel': {
      height: '100%'
    },
    '.alice-carousel__stage-item': {
      listStyle: 'none',
      width: '23rem !important'
    },

    'alice-carousel__stage': {
      overflow: 'hidden',
      display: 'flex',
      padding: 0
    },

    '.alice-carousel__dots': {
      position: 'absolute',
      width: '99%',
      bottom: '0.25rem',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '.alice-carousel__dots-item': {
      // NOTE: !Important is added to over-ride alice-carousel-default-styles
      backgroundColor: `${palette.primary[600]} !important`,
      opacity: 0.2
    },
    '.alice-carousel__dots-item.__active': {
      // NOTE: !Important is added to over-ride alice-carousel-default-styles
      backgroundColor: `${palette.primary.main} !important`,
      opacity: 1
    },

    '& .alice-carousel__next-btn': {
      ...controlCommonStyles,
      right: '-2rem'
    },
    '& .alice-carousel__prev-btn': {
      ...controlCommonStyles,
      left: '-3.25rem'
    },
    '.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item': {
      color: palette.grey.main,
      fontSize: '1.5rem'
    },
    '.alice-carousel__next-btn:empty': {
      display: 'none !important'
    }
  },

  buttonContent: {
    marginTop: spacing(1),
    width: {
      xs: '100%',
      md: 'auto'
    },
    '& div > a > button': {
      width: {
        xs: '100%',
        md: 'auto'
      }
    }
  },

  buttonLinkBox: {
    ':not(:last-child)': {
      marginBottom: spacing(0.5)
    }
  },

  mainContainer: {
    backgroundColor: palette.lightishAqua[400],
    paddingX: globalSectionPadding,
    paddingY: spacing(2)
  },

  headingTitle: {
    color: palette.primary.main,
    width: '100%',
    mb: spacing(1)
  },
  paragraphText: {
    color: palette.grey.main,
    width: '100%',
    mb: spacing(1)
  },
  rightControl: {
    width: '20%',
    position: 'absolute',
    zIndex: 2,
    right: '0',
    top: '0',
    background:
      'linear-gradient(259deg, #F4FAFB 54.94%, rgba(244, 250, 251, 0.00) 90.75%)',
    cursor: 'pointer',
    height: '100%'
  },
  imageOverlay: {
    position: 'absolute',
    right: 0,
    top: '7.5rem',
    opacity: 0.5,
    background:
      'linear-gradient(to left, rgba(246, 246, 246, 0.88) 50%, rgba(246, 246, 246, 0) 100%)',
    width: '25%',
    height: '6rem'
  },
  arrowStyles: { color: palette.grey.main, width: '2rem', height: '2rem' },
  loader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    minHeight: '24.063rem'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'flex-start'
  },
  activeButton: {
    background: palette.primary.light,
    color: palette.grey[50]
  },
  buttonText: {
    textAlign: 'left'
  },
  carouselMarginBottom: {
    marginBottom: { md: '-1.75rem' }
  }
};

export default styles;
