import { SxProps } from '@mui/material';

import theme from '@theme/index';

const { palette, spacing } = theme;

const styles: Record<string, SxProps> = {
  propertyListBox: {
    py: spacing(1),
    // Grid-item width 1rem more than card's width so cards don't stick together and have some space
    width: {
      xs: '22rem',
      sm: '20.25rem'
    }
  },
  LivingContainer: {
    backgroundColor: palette.lightishAqua[400]
  },
  schoolContainerCards: {
    backgroundColor: palette.lightishAqua[400]
  },
  schoolcontainer: {
    backgroundColor: palette.lightishAqua[400]
  },
  container: {
    backgroundColor: palette.lightishAqua[400]
  }
};

export default styles;
