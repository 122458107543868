// libs
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  // Grid,
  Typography,
  // Link,
  IconButton,
  CircularProgress
} from '@mui/material';
import { useRouter } from 'next/router';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteUnfilledIcon from '@mui/icons-material/FavoriteBorder';

// local-imports
import propertyActionConstant from '../../utils/Constants/propertyActionConstant';
import PropertyPill from '../PropertyPills';
import { ResponsiveCarousel } from '../ResponsiveCarousel';
import { Button } from '../Button';
// icons
import VideoTourSolid from '../../icons/VideoTourSolid';
// styles&utils
import {
  getUserFromStorage,
  photoAssetUrlNew,
  // convertPrice,
  saveAuthActionInSession,
  getAuthActionFromSessionStorage
} from '../../utils/index';
import useClientOnly from '../../utils/customHooks/useClientOnly';
import FeesMayApplySection from '../../layouts/FeesMayApplySection';
import { togglePropertyFavorite } from '../../utils/httpRequest';
import styles from './styles';
import { dynamicImport } from '../../utils/reactUtils';

const Alert = dynamicImport(() => import('../Alert'));

export interface TagTypes {
  availableForAuction?: boolean;
  isNewHome?: boolean;
  isBuildToRent?: boolean;
  isReduced?: boolean;
  isSneakPeek?: boolean;
  isRecentSold?: boolean;
  isRecentLet?: boolean;
  landLordAcceptZeroDeposit?: boolean;
  isRecommended?: boolean;
  hopefullyOfInterest?: boolean;
  handpickedForYou?: boolean;
  isNew?: boolean;
  isLastViewed?: boolean;
  isChecked?: boolean;
  isMatched?: boolean;
  isProperty?: boolean;
}
export interface LibPropertyListNewHomesCardProps {
  property?: any;
  isRecommendedProperty?: boolean;
  isPortal?: {
    isPortalCard?: boolean;
    linkSite?: string;
    carouselImages?: Array<{ id: string; title: string; url: string }>;
    address?: string;
    bedrooms?: string;
    bathrooms?: string;
    area?: string;
    isPropertyRent?: boolean;
    isPricePerMonth?: boolean;
    isSneakPeak?: boolean;
    weeklyRent?: string;
    videoTour?: boolean;
    tags?: TagTypes;
  };
  heartIconOnClick?: (e: React.MouseEvent) => void;
  handleLoginClick?: (e: React.MouseEvent) => void;
  favorited?: boolean;
  isEagerImg?: boolean;
  isPropertySelected?: boolean;
  isNewHomePage?: boolean;
  isMapCard?: boolean;
  // cancelSelection?: () => void;
  isNew?: boolean;
  isLastViewed?: boolean;
  isNewHomeListPage?: boolean;
  savedProperties?: any;
  getUserSavedProperties?: () => void;
}

export const numberWithCommas = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getPrice = (
  instructionType: string,
  priceTo: number,
  priceFrom: number
) => {
  if (instructionType === 'sale') {
    if (priceTo !== priceFrom) {
      return `£${numberWithCommas(
        Math.round(priceFrom)
      )} to £${numberWithCommas(Math.round(priceTo))}`;
    }

    return `£${numberWithCommas(Math.round(priceFrom))}`;
  }

  if (priceTo !== priceFrom) {
    return `£${numberWithCommas(Math.round(priceFrom))} - £${numberWithCommas(
      Math.round(priceTo)
    )} pw`;
  }

  return `£${numberWithCommas(Math.round(priceFrom))} pw`;
};

function LibPropertyListNewHomesCard({
  property,
  isRecommendedProperty = false,
  isPortal = { isPortalCard: false },
  heartIconOnClick,
  handleLoginClick,
  favorited = false,
  isEagerImg = false,
  isPropertySelected = false,
  isNewHomePage = false,
  isMapCard = true,
  isNew = false,
  isLastViewed = false,
  isNewHomeListPage = false,
  savedProperties = [],
  getUserSavedProperties
}: LibPropertyListNewHomesCardProps) {
  const { user } = getUserFromStorage();
  const isLoggedIn = user?.isLoggedIn;
  const { hasMounted } = useClientOnly();
  const router = useRouter();
  const feeMayApplyRef = React.useRef(null);

  const {
    // type,
    streetName,
    postcodeShort,
    propertyReference,
    bedrooms,
    bathrooms,
    priceTo,
    priceFrom,
    pricePcm,
    propertyBlob,
    isSneakPeek,
    isReduced,
    isRecentSold,
    isRecentLet,
    isNewHome,
    buildToRent,
    hasVideo,
    instructionType,
    availableForAuction,
    webName
  } = property || {};
  const [listSavedProp, setListSavedProp] = useState(savedProperties || null);
  const {
    floorArea,
    locationName,
    landLordAcceptZeroDeposit,
    priceAskingType
  } = propertyBlob || {};

  useEffect(() => {
    if (isLoggedIn && (isMapCard || isNewHomeListPage)) {
      isPropertySaved();
    }
  }, [listSavedProp]);

  useEffect(() => {
    setListSavedProp(savedProperties);
  }, [savedProperties]);

  useEffect(() => {
    const { redirectUrl, hasLoggedIn, action, formData } =
      getAuthActionFromSessionStorage();

    if (
      isLoggedIn &&
      redirectUrl === router.asPath &&
      hasLoggedIn &&
      (isMapCard || isNewHomeListPage) &&
      action === propertyActionConstant.saveProperty &&
      savedProperties?.length >= 0 &&
      sessionStorage.getItem('propertyAction')
    ) {
      const isAlreadySaved = isPropertySaved(true);

      if (!isAlreadySaved) {
        toggleSaveProperty(
          formData.propertyReference || '',
          formData.instructionType
        );
      }

      sessionStorage.removeItem('propertyAction');
    }
  }, [savedProperties]);
  // ================================================================================
  // Property: Save Status Monitoring ==========================================
  const [isPropSaved, setIsPropSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState<Notification>({
    hasNotification: false,
    notificationContent: '',
    notificationType: 'success'
  });

  // CONDITIONS =================================================================
  const isSneakPeakOverlay = () => isSneakPeek && !isLoggedIn && hasMounted;

  // Locating properties of newhomes page which has collection of other newhomes properties
  const isNewHomeDevelopment =
    property?.inUkDevelopment === 1 &&
    instructionType === 'sale' &&
    (property?.class === 'development' || property?.class === 'category');

  const isBuildToRentDevelopment =
    buildToRent === 1 &&
    instructionType === 'letting' &&
    (property?.class === 'development' || property?.class === 'category');

  // Evaluating Auction Properties (6)
  const isUpForAuction = availableForAuction === 1;

  // Evaluating Properties with zero deposit (5)
  const islandLordAcceptingZeroDeposit = landLordAcceptZeroDeposit === 1;

  // If property is selected via map or otherwise, it should be highlighted
  const variableStyles = isPropertySelected
    ? {
        ...styles.highlightCard,
        ...(property?.isLocalLifeDetailPage && styles.localLifeCard),
        ...(isNewHomeListPage && styles.isNewHomePageCard)
      }
    : {
        ...styles.baseStateOpen,
        ...(property?.isLocalLifeDetailPage && styles.localLifeCard),
        ...(isNewHomeListPage && styles.isNewHomePageCard)
      };

  // ============================================================================

  // CONSTANTS ==================================================================
  const tagsList: TagTypes = {
    isRecommended: isRecommendedProperty,
    isNewHome: isNewHome && instructionType === 'sale',
    isBuildToRent: isNewHome && instructionType === 'letting',
    isReduced,
    isSneakPeek,
    isRecentSold,
    isRecentLet,
    availableForAuction: isUpForAuction,
    landLordAcceptZeroDeposit: islandLordAcceptingZeroDeposit,
    isNew,
    isLastViewed
  };

  const getPropertyUrl = (propertyType: string) => {
    const searchPagesPaths: { [key: string]: string } = {
      sale: 'properties-for-sale',
      letting: 'properties-to-rent',
      short_letting: 'short-let-properties'
    };

    return `${searchPagesPaths[propertyType]}`;
  };

  const address = locationName
    ? `${streetName}, ${locationName}, ${postcodeShort}`
    : `${streetName}, ${postcodeShort}`;

  const hrefLink =
    isNewHomeDevelopment || isBuildToRentDevelopment
      ? `/${isBuildToRentDevelopment ? 'buildtorent' : 'newhomes'}/${webName}`
      : `/${getPropertyUrl(
          instructionType
        )}/${postcodeShort?.toLowerCase()}/${propertyReference}`;

  const picsCarousel = property?.propertyBlob?.assetInfo?.assets?.photos.map(
    (photo: {
      index: string;
      propertyReference: string;
      filename: string;
      timestamp: string;
    }) => ({
      id: photo?.index,
      title: photo?.propertyReference,
      url: photoAssetUrlNew(
        photo?.propertyReference,
        photo?.filename,
        photo?.timestamp
      )
    })
  );

  // ==============================================================================

  // Finding the current property from list of total saved properties
  const isPropertySaved = (check = false) => {
    const savedProperty = listSavedProp?.find(
      (item: {
        property: {
          property: {
            propertyReference: string;
          };
          instructionPrice: {
            instructionPriceType: string;
          };
        };
      }) =>
        item?.property?.property?.propertyReference === propertyReference &&
        item?.property?.instructionPrice?.instructionPriceType ===
          instructionType
    );

    if (savedProperty && !check) {
      setIsPropSaved(true);
    }

    return Boolean(savedProperty);
  };

  // Toggling of saveIcon
  const toggleSaveProperty = async (
    propReference: string,
    propInstructionType: string
  ) => {
    const isSaved = isPropertySaved(true);

    setIsPropSaved((prev) => !prev);

    if (isSaved) {
      const newSavedArray = listSavedProp?.filter(
        (item) =>
          !(
            item.property?.property?.propertyReference === propReference &&
            item.property?.instructionPrice?.instructionPriceType ===
              propInstructionType
          )
      );

      setListSavedProp(newSavedArray);
    }

    const response = await togglePropertyFavorite(
      propInstructionType,
      propReference,
      isSaved
    );

    if (response.success) {
      setIsLoading(false);
      getUserSavedProperties?.();
    } else {
      setIsLoading(false);
      setIsPropSaved((prev) => !prev);
      setNotification({
        hasNotification: true,
        notificationContent: 'Something went wrong. Please try again.',
        notificationType: 'error'
      });
    }
  };

  // Handle Save-Icon Click functionality
  const saveIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    // if user is loggedIn
    if (isLoggedIn) {
      toggleSaveProperty(propertyReference || '', instructionType);
    } else if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'new') {
      // If user not logged in, redirecting to auth-screens
      saveAuthActionInSession({
        actionName: 'save-property',
        hasLoggedIn: false,
        redirectUrl: router.asPath,
        formData: {
          propertyReference: propertyReference || '',
          instructionType: instructionType || ''
        },
        propertyData: {
          area: floorArea ? `${floorArea} sq.ft` : '',
          bath: bathrooms || '',
          bed: bedrooms || '',
          locationName: locationName || '',
          postcodeShort: postcodeShort || '',
          streetName: streetName || '',
          isSneakPeek: isSneakPeek || false,
          price:
            getPrice(instructionType || '', priceTo || 0, priceFrom || 0) || '',
          image: picsCarousel[0]?.url || ''
        }
      });
      router.push('/auth/action');
    } else {
      const savePropertyData = {
        type: 'save_property',
        data: {
          property_id: property?.propertyId || '',
          rating: 5,
          return_url: `${process.env.NEXT_PUBLIC_URL}${router.asPath}`
        }
      };

      localStorage.setItem('account_action', JSON.stringify(savePropertyData));
      router.push(
        `/account/welcome/save_property?property_id=${property?.propertyId}`
      );
    }
  };

  const saveIconStatus = () => {
    if (isLoading) {
      return <CircularProgress color="inherit" size={8} />;
    }

    // isPropertyMarkedSaved or not
    const saveStatus = isMapCard || isNewHomeListPage ? isPropSaved : favorited;

    return saveStatus ? (
      <FavoriteIcon sx={styles.iconFilled} />
    ) : (
      <FavoriteUnfilledIcon sx={styles.icon} />
    );
  };
  // ================================================================================

  const handleFeesMayApplyButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    feeMayApplyRef?.current?.handleDrawerOpen();
  };

  const getPortalAuthUrl = () => {
    if (process.env.NEXT_PUBLIC_PORTAL_VERSION === 'new') {
      router.push('/auth/action');
    }

    const redirectUrl = {
      redirect_url: `${process.env.NEXT_PUBLIC_URL}${router.asPath}`
    };

    localStorage.setItem('sneakpeek', JSON.stringify(redirectUrl));

    router.push('/account/welcome/sneakpeek/');
  };

  /* eslint-disable no-nested-ternary */
  return (
    <>
      <Card
        sx={isSneakPeakOverlay() ? styles.baseStateSneakPeak : variableStyles}
      >
        {/* Is property sneak Peek: Show solid overlay  */}
        {isSneakPeakOverlay() ? (
          <Box sx={styles.overlayWrapper}>
            <Typography variant="h5" sx={styles.sneakPeakTextMain}>
              Sneak peek
            </Typography>
            <Typography variant="body1" sx={styles.sneakPeakTextSecondary}>
              Sign in to access sneak peeks
            </Typography>

            <Button
              variant="primary"
              sx={styles.signInButton}
              onClick={(e) => {
                getPortalAuthUrl();
                handleLoginClick?.(e);
              }}
            >
              Sign in
            </Button>
          </Box>
        ) : null}

        {!isSneakPeek || (isLoggedIn && hasMounted) ? (
          <CardActionArea
            href={hrefLink}
            target="_blank"
            sx={{ position: 'initial', padding: 0.5 }}
            disableRipple
            disableTouchRipple
          >
            <Box sx={styles.imageContainer}>
              {/* Video Icons */}
              {hasVideo ? (
                <Box sx={styles.videoTourIconBox}>
                  <VideoTourSolid />
                </Box>
              ) : null}

              {/* Tag Names */}
              <PropertyPill
                totalNoTags={2}
                tagsList={tagsList}
                isPortal={isPortal?.isPortalCard}
              />

              {/* Carousel Images */}
              {!isPortal?.isPortalCard && (
                <ResponsiveCarousel
                  isDisableFullscreen
                  isDisableSlideCount
                  isTransparentNavigation
                  isPropertyListCards
                  isEagerImg={isEagerImg}
                  dataImg={picsCarousel?.map((img) => ({
                    id: img.id,
                    title: img.title,
                    srcSet: img.url
                  }))}
                />
              )}
            </Box>

            {/* Card Content */}
            <CardContent sx={styles.cardContainer}>
              <Box sx={styles.detailsContainer}>
                {/* Address */}
                <Box sx={styles.addressAndOtherDetails}>
                  {address && (
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        ...styles.addressText,
                        ...(isNewHomePage && styles.newHomeAddressText)
                      }}
                      className="addressText"
                    >
                      {address}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={styles.priceBlock}>
                {/* Price Text */}
                <div>
                  <Typography variant="h4" sx={styles.weeklyRent}>
                    {priceAskingType && `${priceAskingType} `}
                    {getPrice(instructionType, priceTo, priceFrom)}
                  </Typography>
                  {isBuildToRentDevelopment && (
                    <>
                      <Typography inline sx={styles.monthlyRent}>
                        {` £${numberWithCommas(pricePcm)} pcm`}
                      </Typography>
                      <Button
                        sx={styles.drawerBtn}
                        onClick={handleFeesMayApplyButton}
                      >
                        Fees may apply
                      </Button>
                    </>
                  )}
                </div>

                {/* Save Icon */}
                {/* saveIconClick: internal saving function,  heartIconOnClick: toggling of saving from outside func */}
                <IconButton
                  onClick={
                    isMapCard || isNewHomeListPage
                      ? (e) => saveIconClick(e)
                      : heartIconOnClick
                  }
                  aria-label="favorite"
                  sx={styles.iconBtn}
                >
                  {saveIconStatus()}
                </IconButton>
              </Box>
            </CardContent>
          </CardActionArea>
        ) : null}
      </Card>

      <Alert
        open={notification.hasNotification}
        severity={notification.notificationType}
        title={notification.notificationType}
        description={notification.notificationContent}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() =>
          setNotification((prevState) => ({
            ...prevState,
            hasNotification: false
          }))
        }
        autoHideEnable
        autoHideAfter={3000}
      />

      {isBuildToRentDevelopment ? (
        <FeesMayApplySection
          propertyType={instructionType}
          propertyName={address}
          propertyPrice={priceTo}
          ref={feeMayApplyRef}
        />
      ) : null}
    </>
  );
}

export const PropertyListNewHomesCard = React.memo(LibPropertyListNewHomesCard);

/*
PROPS: 
01) isRecommended: prop to display chip of Recommended in cases where it needs to be seen. It wouldnt be shown on property search page card or property detail page. Will only be shown in sections like Recommended Prperties.
02) isPortal: To differentiate between properties coming via web or portal
03) basePath: to evaluate url
04) isMapCard: to track where these cards are displayed via map pins. Boolean false for cards displayed in grid list and Boolean true for cards displayed on map. Currently it is also linked with saved functionality being operated internally. 

CASES:
01) Parking property can be from sproperty of sale and property of rent: In case of property of sale, calculating mortgages will be shown while in case of rente properties, fees may apply be seen.
02) In case of parking properties, only area would be shown, no bedroom and/or bathrooms 
03) isRentProperty: evaluating that property is short let or long let property
04) isRecentSold: if property is recently sold, no need to show either text below (e.g. fees may.. or calc mortage..).
05) islandLordAcceptingZeroDeposit: would show up on properties-for-rent
06) isUpForAuction: would show up on properties-for-sale
07) property specs (i.e. no-of-bedroom, bathroom) would not be shown for isNewHomeDevelopment properties; also note point#02

*/
