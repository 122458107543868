import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import { boxShadow } from '../../theme/colorPalette';

const { palette, spacing } = lightTheme;

const highlightedCard = {
  width: '19.25rem',
  height: '100%',
  padding: '1rem',
  display: 'block',
  position: 'relative',

  backgroundColor: palette.primary['400'],
  '.addressText': {
    color: palette.primary.main
  },
  '.iconText': {
    color: palette.primary.main
  },
  '.svgIcon': {
    '& svg': {
      '& path': {
        fill: palette.primary.main
      }
    }
  }
};

const styles: Record<string, SxProps> = {
  baseStateOpen: {
    width: '19.25rem',
    height: '100%',
    backgroundColor: palette.grey['50'],
    display: 'block',
    position: 'relative',
    boxShadow: boxShadow['300'],
    borderRadius: '0.5rem',
    a: {
      height: '100%'
    },

    '& .highlightCard': {
      ...highlightedCard
    },

    '.svgIcon': {
      '& svg': {
        '& path': {
          fill: palette.grey[400]
        }
      }
    },
    '&:hover': {
      backgroundColor: palette.primary['400'],
      boxShadow: '0px 24px 32px 0px #97979766',
      '.addressText': {
        color: palette.primary.main
      },

      '.iconText': {
        color: palette.primary.main
      },

      '.svgIcon': {
        '& svg': {
          '& path': {
            fill: palette.primary.main
          }
        }
      }
    }
  },

  baseStateSneakPeak: {
    minWidth: '16rem',
    width: '19.25rem',
    height: '100%',
    minHeight: '25rem',
    padding: '1rem',
    backgroundColor: palette.grey['100'],
    display: 'block',
    position: 'relative',

    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      background: palette.primary.main,
      opacity: '1',
      zIndex: '2'
    }
  },

  cardContainer: {
    padding: 0,
    marginBottom: '0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // height: '48%',
    '&:last-child': {
      paddingBottom: '0rem'
    }
  },

  detailsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  addressText: {
    marginBottom: spacing(1),
    color: palette.grey.main,
    wordBreak: 'break-word',
    fontWeight: 600
  },
  addressAndOtherDetails: {
    marginTop: spacing(0.5)
    // minHeight: '3.95rem'
  },
  carouselContainer: {
    marginTop: '-0.25rem',
    display: 'flex',
    height: '12.625rem'
  },

  priceBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginTop: '0.51rem',
    height: '3.5rem',
    gap: spacing(1)
  },

  weeklyRent: {
    color: palette.primary.main,
    alignSelf: 'flex-end'
  },
  monthlyRent: {
    color: palette.primary.main
  },

  iconTextWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    svg: {
      width: '1rem',
      height: 'auto'
    }
  },

  iconText: {
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1rem',
    color: palette.grey[400],
    marginLeft: '0.25rem',
    display: 'flex',
    alignItems: 'center'
  },

  imageContainer: {
    display: 'block',
    img: {
      height: '12rem !important' // overriding responsive carousel image height for list card only
    }
  },

  carouselArrowNext: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    cursor: 'pointer',
    color: palette.grey[50],
    svg: {
      fill: palette.grey[50]
    }
  },

  featureWrapper: {
    marginTop: '0.625rem'
  },

  carouselArrowPrev: {
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    cursor: 'pointer',
    color: palette.grey[50],
    svg: {
      fill: palette.grey[50]
    }
  },

  imgCarousel: {
    objectFit: 'cover'
  },

  videoTourIconBox: {
    position: 'absolute',
    top: '1.5rem',
    left: '1.5rem',
    zIndex: '2'
  },

  tagNameBox: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    zIndex: '2',
    width: '62%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    gap: '0.25rem',
    '& > div': {
      gap: '0.5rem',
      display: 'flex',
      alignItems: 'center'
    }
  },

  overlayWrapper: {
    zIndex: 3,
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  sneakPeakTextMain: {
    fontSize: '2rem',
    lineHeight: '2.25rem',
    fontWeight: '700',
    color: palette.secondary.main,
    marginBottom: spacing(0.5)
  },

  sneakPeakTextSecondary: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: '500',
    color: palette.grey['50'],
    marginBottom: spacing(1)
  },

  btnLink: {
    textDecoration: 'none'
  },

  btnBox: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',
    width: '100%',
    '& > button': {
      width: '100%',
      height: '2.12rem',
      fontSize: '0.75rem',
      textTransform: 'none'
    }
  },

  iconBtn: {
    boxShadow: boxShadow.main,
    height: '1.4625rem',
    width: '1.4625rem',
    alignSelf: 'flex-end'
  },

  icon: {
    color: palette.grey.main,
    height: '0.853rem',
    width: '0.848rem'
  },
  iconFilled: {
    color: palette.primary.main,
    height: '0.853rem',
    width: '0.848rem'
  },

  newHomeAddressText: {
    minHeight: '2.75rem',
    mt: '0.5rem'
  },

  priceRange: {
    fontSize: {
      xs: '0.75rem',
      md: '0.875rem'
    },
    mb: '1.5rem'
  },
  sneakPeekLineHeight: {
    mt: '0.6rem',
    mb: '1.07rem'
  },

  localLifeAddressText: {
    minHeight: '1.5rem',
    mb: '0.1rem',
    fontSize: '1.25rem',
    fontWeight: 400
  },
  localLifeArea: {
    color: palette.grey[400],
    mb: '0rem'
  },
  signInButton: {
    textTransform: 'none'
  },

  drawerBtn: {
    fontSize: '0.875rem',
    textDecoration: 'underline',
    textTransform: 'none',
    padding: '0',
    height: '0',
    textAlign: 'left'
  },

  localLifeCard: {
    minHeight: '5rem !important',
    paddingY: '0.01rem',
    paddingX: '0.1rem',
    minWidth: '15rem',
    width: '16rem'
  },

  localLifeCardTitle: {
    color: palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 700
  },

  closeIcon: {
    width: 18,
    height: 18,
    float: 'right'
  },

  saleInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    py: '0.6rem'
  },

  saleText: {
    fontWeight: 400,
    fontSize: '0.875rem',
    pl: '0.4rem',
    color: palette.grey['500']
  },
  isNewHomePageCard: {
    height: 'auto'
  }
};

export default styles;
