// Rob - Temporary fix until I can upgrade NextJs to v14 or higher
/* eslint-disable react/destructuring-assignment */

import React, { ReactElement } from 'react';
import ReactImage from './ReactImageComponent';

import { isReactApp, dynamicImport } from '../../utils/reactUtils';

const NextImage = dynamicImport(() => import('next/image'));

function CImageComponent(props): ReactElement {
  if (!isReactApp()) {
    // If in Next.js environment, use Next.js Image component
    return <NextImage {...props} unoptimized />;
  }

  // If in a different environment, use your custom React Image component
  // ReactImage doesn't support onLoadComplete, so setting to onLoad here
  // we should change to the onLoad event for NextImage when we upgrade past 14.0.0
  return (
    <React.Suspense>
      <ReactImage onLoad={props?.onLoadingComplete} {...props} />
    </React.Suspense>
  );
}

export default CImageComponent;
